// material
import { styled } from "@mui/material/styles";
//
import ImagoType from "./ImagoType";
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function LoadingScreen({ ...other }) {
  return (
    <>
      <RootStyle {...other}>
        <Box sx={{ textAlign: "center" }}>
          <ImagoType width={180} height={360} />
        </Box>
      </RootStyle>
    </>
  );
}
